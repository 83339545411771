import React from 'react';
  
const Footer = () => {
    return(
        <footer className="bg-dark">
          <div className="mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
            <div className="mt-8 md:mt-0 md:order-1">
              <p className="text-center text-base text-green">&copy; 2022 Jonah Nelson. All rights reserved.</p>
            </div>
          </div>
        </footer>
    )
}

export default Footer;